<template>
	<div class="lobby-screen">
		<div class="main-column">
			<div class="lobby">
				<div class="logo">
					<img src="@/assets/logo.png"/>
				</div>
				
				<div class="admin-info" v-if="superuser">
					<div class="col-padding">
						<div>NUOVA PARTITA</div>
						
						<div class="info">
							<div class="match-id">
								<span class="label">MATCH ID:</span> 
								<span class="value">{{match_id}}</span> 
							</div>
							<div class="code" v-if="superuser">
								<span class="label">CODE:</span> 
								<span class="value">{{code}}</span> 
							</div>
						</div>
					</div>
				
					<div class="users row-padding col-padding">
						<div class="uppercase small-bottom-padding">Utenti accettati:</div>
						<div v-for="user in accepted_users" :key="user.ID" class="user flex space v-center">
							<div class="name">{{user.name}}</div>
						</div>
					</div>
					
					<div class="button arrow"  :class="{loading : starting_game}" @click="startGame">Avvia partita</div>
				</div>
				
				<div class="user-info" v-else>
					<div class="large-font text-center">{{user_name}}</div>
					
					<div class="status row-padding text-center uppercase">
						<div v-if="status == 'to_be_accepted'">In attesa di essere accettato</div>
						<div v-if="status == 'accepted'">STIAMO ASPETTANDO GLI ALTRI CITTADINI DI LIFE BEWARE VALLEY, TRA QUALCHE MINUTO COMINCIA LA PARTITA!</div>
					</div>
				</div>
			</div>
		</div>
		
		<transition name="fade">
			<div class="accept-user-modal" v-if="superuser && to_be_accepted_users.length > 0">
				<div class="background"></div>
				
				<div class="main-column">
					<div class="accept-user" v-for="user in to_be_accepted_users" :key="user.ID">
						<div class="text-center">
							<div class="uppercase">Richiesta di accesso di</div>
							<div class="large-font small-row-padding">{{user.name}}</div>
						</div>
						
						<div class="actions flex space">
							<div class="button arrowless green-bkg" @click="acceptUser(user)"> Accetta</div>
							<div class="button arrowless" @click="rejectUser(user)"> Rifiuta</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import { helperMixin } from '@/store/helper.js';
	import axios from 'axios';
	
	export default {
		name: 'Lobby',
		mixins : [helperMixin],
		data() {
			return {
				starting_game : false
			};
		},
		computed: {
			accepted_users(){
				var users = this.users_list.filter(user => user.status == 'accepted' );
				return users; 
			},
			to_be_accepted_users(){
				var users = this.users_list.filter(user => user.status == 'to_be_accepted' );
				return users; 
			}
		},
		methods : {
			acceptUser(user){
				axios.post(this.api_url+'accept_user', {
					user_id : this.user_id, 
					match_id : this.match_id, 
					accept_id : user.ID
				});
				user.status = 'accepted';
			},
			rejectUser(user){
				axios.post(this.api_url+'reject_user', {
					user_id : this.user_id, 
					match_id : this.match_id, 
					reject_id : user.ID
				});
				user.status = 'rejected';
			},
			startGame(){
				this.starting_game = true;
				axios.post(this.api_url+'start_game', { user_id : this.user_id,  match_id : this.match_id })
					.then(function (response) {	
						if(response.status == 200 && response.data.success){
							this.$store.dispatch('startGame');
							this.$router.replace({ path: '/game' });
						}
					}.bind(this));
			}
		}
	}
</script>


<style lang="scss">
	@import "../assets/styles/variables.scss";
	
	.lobby-screen{
		position: relative;
		width: 100%;
		min-height: 100vh;
		background: $red;
		display: flex;
		align-items: center;
		
		.main-column{
			height: 100%;
			display: flex;
			align-items: center;
		}
		
		.lobby{
			position: absolute;
			left: 0;
			top: 50%;
			width: 100%;
			transform: translateY(-50%);
			background: #ffffff;
			border-radius: 35px;
			padding: 50px 25px;	
			margin: 25px 0;
			color: $red;

			.logo{
				position: absolute;
				bottom: 100%;
				padding-left: 25px;
				padding-bottom: 50px;
				
				img{
					width: 200px;
				}
			}			
			
			.info{
				padding-top: 25px;
				
				> div .label{
					display: inline-block;			
					width: 120px;
				}
				.match-id{
					margin-bottom: 5px;
				}
				
			}
			
			.user{
				margin-bottom: 5px;
				
				.button{
					margin-left: 10px;
				}
			}
		}
		
		.accept-user-modal{
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			
			.background{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0.5);
			}
			
			.accept-user{
				position: absolute;
				left: 0;
				top: 50%;
				width: 100%;
				transform: translateY(-50%);
				background: #ffffff;
				border-radius: 35px;
				padding: 50px 25px;
				color: #EA4F35;
				
				.actions{
					margin-top: 25px;
				}
			}
		}
	}
</style>